import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'

import Head from '@tofu/apps/account/providers/head'
import Link from '@tofu/apps/account/components/common/link'

const Homepage = () => {
  const router = useRouter()

  useEffect(() => {
    router.push('/deliveries')
  }, [router])

  return (
    <>
      <Head
        description='Accounts area for allplants'
        title='allplants | account'
      />
      <Box mt={4}>
        <Text as='p' mt={4}>
          View <Link href='/deliveries'>my deliveries</Link>
        </Text>
      </Box>
    </>
  )
}

export default Homepage
